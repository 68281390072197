import { render, staticRenderFns } from "./AgeSelector.vue?vue&type=template&id=74e2eeac&scoped=true"
import script from "./AgeSelector.vue?vue&type=script&lang=js"
export * from "./AgeSelector.vue?vue&type=script&lang=js"
import style0 from "./AgeSelector.vue?vue&type=style&index=0&id=74e2eeac&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74e2eeac",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VSelect})
