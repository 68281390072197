<template>
  <div
    class="contain-select-width"
  >
    <v-select
      :items="optionItems"
      :dense="dense"
      outlined
      hide-details
      :label="label"
      v-bind="$props"
      v-on="$listeners"
    ></v-select>
  </div>
</template>

<script>
export default {
  name: "AgeSelector",

  props: {
    label: {
      type: String,
      required: false,
      default: "Percentage"
    },

    value: {
      type: Number,
      required: false,
      default: 13
    },

    dense: {
      type: Boolean,
      required: false,
      default: true
    },

    minValue: {
      type: Number,
      required: false,
      default: 0
    },

    maxValue: {
      type: Number,
      required: false,
      default: 110
    },
  },

  data: () => ({
    items: [
      {
        text: "13",
        value: 13,
      },
      {
        text: "18",
        value: 18,
      },
      {
        text: "25",
        value: 25,
      },
      {
        text: "35",
        value: 35,
      },
      {
        text: "45",
        value: 45,
      },
      {
        text: "65",
        value: 65,
      },
      {
        text: "65+",
        value: 100,
      }
    ]
  }),

  computed: {
    optionItems() {
      return this.items
        // for minimum
        .filter((item) => item.value >= this.minValue)
        // for maximum
        .filter((item) => item.value < this.maxValue)
    }
  },

  mounted() {
    this.$emit('input', this.value)
  }
}
</script>

<style lang="stylus" scoped>
.contain-select-width
  min-width 8em
  width 8em
</style>
